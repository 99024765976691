import type { RouteLocationNormalizedGeneric } from 'vue-router';

export default defineNuxtRouteMiddleware(
   async (
      to: RouteLocationNormalizedGeneric,
      from: RouteLocationNormalizedGeneric
   ) => {
      const { anonymousData } = storeToRefs(useClientAuthStore());
      const { clientProfile, status, clientInformation } =
         storeToRefs(useClientInfo());
      const { isClientAuthenticated } = useAuthClientStorage();
      const { getClientInfo, getSelectedTherapistInfo } = useClientInfo();
      if (isClientAuthenticated?.value) {
         if (!clientProfile.value) {
            await Promise.all([getClientInfo(), getSelectedTherapistInfo()]);
         }
         if (
            clientProfile.value?.timezone == null ||
            clientProfile.value?.timezone == ''
         ) {
            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const { updateTimeZone } = useTimeZoneStore();
            await updateTimeZone(timeZone, true);
         }

         if (clientProfile.value?.status == 3) {
            return navigateTo({ name: 'insurance-pending' });
         } else if (clientProfile.value?.status == 4) {
            return navigateTo({ name: 'insurance-failed' });
         } else if (clientProfile.value?.status == 1) {
            if (!clientProfile.value?.therapy_type?.id) {
               return navigateTo({
                  name: 'auth-client-register-confirmedInsuranceBenefitCode',
               });
            }
            const isInsurance =
               clientProfile.value?.sponsor == SponsorType.Insurance ||
               anonymousData.value?.sponsor == SponsorType.Insurance;
            if (isInsurance && status.value?.statusCode == 0) {
               return navigateTo(status.value?.route?.to);
            }
            if (
               from.name == 'auth-client-login' ||
               from.name == 'auth-client-register-verify-email' ||
               from.name == 'auth-client-register-therapist-preference'
            ) {
               if (
                  !clientInformation?.value?.therapist?.id &&
                  to.name !== 'therapist-recommendations'
               ) {
                  return navigateTo({ name: 'therapist-recommendations' });
               }
            }
         }
      } else {
         return navigateTo({ name: 'auth-client-login' });
      }
   }
);
